import styled from 'styled-components'

const JoinSectionStyle = styled.div`
    --jsInfoRadius: var(--sp4x);
    --jsInfoBorderWidth: var(--sp0-5x);
    --jsColPaddingV: var(--sp1x);
    --jsLeftColWidth: 51.523vw;
    --jsInfoPadding: var(--sp13x) var(--sp20x) var(--sp13x) var(--sp13x);
    --jsTitleMarginRight: var(--sp13x);
    --jsImageWidth: 5.76vw;
    --jsImageProportion: 152.72%;
    --jsButtonMarginTop: var(--sp1x);

    display: flex;

    margin: 0 calc(var(--jsColPaddingV) / -2);

    .js-col {
        display: flex;
        flex-direction: column;
        padding: calc(var(--jsColPaddingV) / 2);

        &.js-left-cont {
            max-width: var(--jsLeftColWidth);
            width: 100%;
        }

        &.js-right-cont {
            flex: 1;
        }

        .js-info-cont {
            display: flex;
            align-items: center;
            flex: 1;
            padding: var(--jsInfoPadding);
            border: var(--jsInfoBorderWidth) solid var(--birodoGreen);
            border-radius: var(--jsInfoRadius);

            .js-title {
                margin-right: var(--jsTitleMarginRight);
            }

            .js-col-image {
                width: 100%;
                max-width: var(--jsImageWidth);

                .image-cont {
                    --proportion: var(--jsImageProportion);

                    img {
                        object-fit: contain;
                    }
                }
            }
        }

        .js-offer-button {
            margin-top: var(--jsButtonMarginTop);
        }
    }
    
    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
        --jsInfoRadius: var(--sp4x);
        --jsInfoBorderWidth: var(--sp0-5x);
        --jsLeftColWidth: 51.979vw;
        --jsInfoPadding: var(--sp10x) var(--sp15x) var(--sp10x) var(--sp10x);
        --jsTitleMarginRight: var(--sp10x);
        --jsImageWidth: 5.729vw;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
        --jsInfoRadius: var(--sp2-5x);
        --jsInfoBorderWidth: var(--sp0-5x);
        --jsLeftColWidth: 51.979vw;
        --jsInfoPadding: var(--sp8x) var(--sp10x) var(--sp8x) var(--sp8x);
        --jsTitleMarginRight: var(--sp7x);
        --jsImageWidth: 5.416vw;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
        --jsInfoRadius: var(--sp2-5x);
        --jsInfoBorderWidth: var(--sp0-5x);
        --jsLeftColWidth: 50.972vw;
        --jsInfoPadding: var(--sp8x) var(--sp10x) var(--sp8x) var(--sp8x);
        --jsTitleMarginRight: var(--sp5x);
        --jsImageWidth: 7.222vw;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
        --jsInfoRadius: var(--sp2-5x);
        --jsInfoBorderWidth: var(--sp0-5x);
        --jsLeftColWidth: 52.656vw;
        --jsInfoPadding: var(--sp7x) var(--sp9x) var(--sp7x) var(--sp7x);
        --jsTitleMarginRight: var(--sp6x);
        --jsImageWidth: 6.25vw;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
        --jsInfoRadius: var(--sp2x);
        --jsInfoBorderWidth: 3px;
        --jsLeftColWidth: 54.88vw;
        --jsInfoPadding: var(--sp5x) var(--sp8x) var(--sp5x) var(--sp5x);
        --jsTitleMarginRight: var(--sp6x);
        --jsImageWidth: 6.34vw;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
        --jsInfoRadius: var(--sp2x);
        --jsInfoBorderWidth: 3px;
        --jsLeftColWidth: 55.2vw;
        --jsInfoPadding: var(--sp5x);
        --jsTitleMarginRight: var(--sp5x);
        --jsImageWidth: 8.46vw;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
        --jsInfoRadius: var(--sp1-5x);
        --jsInfoBorderWidth: 3px;
        --jsLeftColWidth: 100%;
        --jsInfoPadding: var(--sp5x);
        --jsTitleMarginRight: var(--sp5x);
        --jsImageWidth: 13.768vw;

        flex-wrap: wrap;
        
        .js-col {
            .js-info-cont {
                justify-content: space-between;
            }

            &:not(:first-child) {
                margin-top: var(--sp2x);
            }
        }
        
	}
`

export default JoinSectionStyle