import styled from 'styled-components'

const HeroSectionStyle = styled.section`
	--hsPadding: var(--sp4x);
	--hsContPaddingTop: var(--sp27x);
	--hsContPaddingH: 0px;
	--hsContBorderRadius: var(--sp4x);
	--hsInfoMaxWidth: var(--sp90x);
	--hsTopBtnMarginTop: var(--sp9x);
	--hsAnimRow1PaddingTop: 0;
	--hsAnimWidth: 9.765vw;
	--hsAnimRow1MarginRight: 5.85vw;
	--hsAnimRow1MiddleMarginTop: 15.25vw;
	--hsAnimRow2MarginTop: -6vw;
	--hsAnimRow2MarginRight: 10.42vw;
	--hsAnimRow2MiddleMarginTop: 4.867vw;
	--hsAnimMiddleWidth: 12.382vw;
	--hsAnimMiddleMarginTop: 17.968vw;
	--hsSubtitleMaxWidth: var(--sp180x);
	--hsSubtitleMarginTop: var(--sp13x);
	--hsTextMaxWidth: var(--sp138x);
	--hsTextMarginTop: var(--sp9x);
	--hsBottomBtnMarginTop: var(--sp9x);
	--hsTitleMarginBottom: var(--sp9x);

	padding: var(--hsPadding) var(--hsPadding) 0 !important;

	.hs-container {
		background: var(--birodoGreen);
		border-radius: var(--hsContBorderRadius);

		.hs-inner-container {
			position: relative;
			padding-top: var(--hsContPaddingTop);
			padding-left: calc(var(--contPaddingLR) - var(--hsPadding) + var(--hsContPaddingH));
			padding-right: calc(var(--contPaddingLR) - var(--hsPadding) + var(--hsContPaddingH));
			padding-bottom: var(--sectionDistance);

			.hs-top-info-cont {
				display: flex;
				flex-direction: column;
				align-items: center;
				max-width: var(--hsInfoMaxWidth);
				position: absolute;
				top: var(--hsContPaddingTop);
				left: 50%;
				transform: translate3d(-50%, 0, 0);
				text-align: center;

				.hs-title {
					margin-bottom: var(--hsTitleMarginBottom);
				}

				.hs-top-btn {
					display: inline-flex;
					margin-top: var(--hsTopBtnMarginTop);
				}
			}

			.hs-anim-row {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;

				.hs-anim-col {
					display: flex;

					.hs-anim {
						width: var(--hsAnimWidth);
						transform: scale(0);
						min-height: 50%;

						.lottie-wrapper {
							min-height: 50%;
						}
					}
				}

				&.hs-anim-row-1 {
					padding-top: var(--hsAnimRow1PaddingTop);

					.hs-anim {
						&:first-child {
							margin-right: var(--hsAnimRow1MarginRight);
						}

						&.hs-anim-2,
						&.hs-anim-3 {
							margin-top: var(--hsAnimRow1MiddleMarginTop);
							margin-top: var(--hsAnimRow1MiddleMarginTop);
						}
					}
				}

				&.hs-anim-row-2 {
					margin-top: var(--hsAnimRow2MarginTop);

					.hs-anim {
						&:first-child {
							margin-right: var(--hsAnimRow2MarginRight);
						}

						&.hs-anim-6,
						&.hs-anim-7 {
							margin-top: calc(var(--hsAnimRow2MiddleMarginTop) - var(--hsAnimRow2MarginTop));
						}
					}

					.hs-anim-middle {
						width: var(--hsAnimMiddleWidth);
						margin-top: calc(var(--hsAnimMiddleMarginTop) - var(--hsAnimRow2MarginTop));
					}
				}
			}

			.hs-bottom-info-cont {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				opacity: 0;

				.hs-subtitle {
					max-width: var(--hsSubtitleMaxWidth);
					margin-top: var(--hsSubtitleMarginTop);
				}

				.hs-text {
					max-width: var(--hsTextMaxWidth);
					margin-top: var(--hsTextMarginTop);
				}

				.hs-bottom-btn {
					margin-top: var(--hsBottomBtnMarginTop);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--hsPadding: var(--sp2-5x);
		--hsContPaddingTop: var(--sp21x);
		--hsContBorderRadius: var(--sp4x);
		--hsInfoMaxWidth: var(--sp68x);
		--hsTopBtnMarginTop: var(--sp7x);
		--hsAnimRow1PaddingTop: var(--sp10x);
		--hsAnimWidth: 9.635vw;
		--hsAnimRow1MarginRight: 5.885vw;
		--hsAnimRow1MiddleMarginTop: 13.64vw;
		--hsAnimRow2MarginTop: -6vw;
		--hsAnimRow2MarginRight: 11.09vw;
		--hsAnimRow2MiddleMarginTop: 4.895vw;
		--hsAnimMiddleWidth: 12.5vw;
		--hsAnimMiddleMarginTop: 18.33vw;
		--hsSubtitleMaxWidth: var(--sp130x);
		--hsSubtitleMarginTop: var(--sp9x);
		--hsTextMaxWidth: var(--sp104x);
		--hsTextMarginTop: var(--sp7x);
		--hsBottomBtnMarginTop: var(--sp7x);
		--hsTitleMarginBottom: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--hsPadding: var(--sp2-5x);
		--hsContPaddingTop: var(--sp20x);
		--hsContBorderRadius: var(--sp2-5x);
		--hsInfoMaxWidth: var(--sp52x);
		--hsTopBtnMarginTop: var(--sp6x);
		--hsAnimRow1PaddingTop: var(--sp6x);
		--hsAnimWidth: 9.635vw;
		--hsAnimRow1MarginRight: 5.629vw;
		--hsAnimRow1MiddleMarginTop: 13.24vw;
		--hsAnimRow2MarginTop: -6vw;
		--hsAnimRow2MarginRight: 12.51vw;
		--hsAnimRow2MiddleMarginTop: 5.364vw;
		--hsAnimMiddleWidth: 12.5vw;
		--hsAnimMiddleMarginTop: 17.74vw;
		--hsSubtitleMaxWidth: var(--sp108x);
		--hsSubtitleMarginTop: var(--sp8x);
		--hsTextMaxWidth: var(--sp80x);
		--hsTextMarginTop: var(--sp6x);
		--hsBottomBtnMarginTop: var(--sp6x);
		--hsTitleMarginBottom: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--hsPadding: var(--sp2-5x);
		--hsContPaddingTop: var(--sp20x);
		--hsContBorderRadius: var(--sp2-5x);
		--hsInfoMaxWidth: var(--sp50x);
		--hsTopBtnMarginTop: var(--sp6x);
		--hsAnimRow1PaddingTop: var(--sp6x);
		--hsAnimWidth: 9.72vw;
		--hsAnimRow1MarginRight: 5.625vw;
		--hsAnimRow1MiddleMarginTop: 13.26vw;
		--hsAnimRow2MarginTop: -6vw;
		--hsAnimRow2MarginRight: 12.15vw;
		--hsAnimRow2MiddleMarginTop: 5.34vw;
		--hsAnimMiddleWidth: 12.5vw;
		--hsAnimMiddleMarginTop: 17.77vw;
		--hsSubtitleMaxWidth: var(--sp98x);
		--hsSubtitleMarginTop: var(--sp8x);
		--hsTextMaxWidth: var(--sp76x);
		--hsTextMarginTop: var(--sp6x);
		--hsBottomBtnMarginTop: var(--sp6x);
		--hsTitleMarginBottom: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--hsPadding: var(--sp2x);
		--hsContPaddingTop: var(--sp16x);
		--hsContBorderRadius: var(--sp2-5x);
		--hsInfoMaxWidth: var(--sp46x);
		--hsTopBtnMarginTop: var(--sp5x);
		--hsAnimRow1PaddingTop: var(--sp10x);
		--hsAnimWidth: 9.76vw;
		--hsAnimRow1MarginRight: 5.78vw;
		--hsAnimRow1MiddleMarginTop: 13.75vw;
		--hsAnimRow2MarginTop: -6vw;
		--hsAnimRow2MarginRight: 12.57vw;
		--hsAnimRow2MiddleMarginTop: 5.34vw;
		--hsAnimMiddleWidth: 13.25vw;
		--hsAnimMiddleMarginTop: 17.03vw;
		--hsSubtitleMaxWidth: var(--sp95x);
		--hsSubtitleMarginTop: var(--sp7x);
		--hsTextMaxWidth: var(--sp71x);
		--hsTextMarginTop: var(--sp5x);
		--hsBottomBtnMarginTop: var(--sp5x);
		--hsTitleMarginBottom: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--hsPadding: var(--sp1-5x);
		--hsContPaddingTop: var(--sp14x);
		--hsContBorderRadius: var(--sp2x);
		--hsInfoMaxWidth: var(--sp38x);
		--hsTopBtnMarginTop: var(--sp5x);
		--hsAnimRow1PaddingTop: var(--sp17x);
		--hsAnimWidth: 10.74vw;
		--hsAnimRow1MarginRight: 6.05vw;
		--hsAnimRow1MiddleMarginTop: 14.35vw;
		--hsAnimRow2MarginTop: -6vw;
		--hsAnimRow2MarginRight: 13.57vw;
		--hsAnimRow2MiddleMarginTop: 3.61vw;
		--hsAnimMiddleWidth: 13.25vw;
		--hsAnimMiddleMarginTop: 17.77vw;
		--hsSubtitleMaxWidth: var(--sp79x);
		--hsSubtitleMarginTop: var(--sp6x);
		--hsTextMaxWidth: var(--sp59x);
		--hsTextMarginTop: var(--sp5x);
		--hsBottomBtnMarginTop: var(--sp5x);
		--hsTitleMarginBottom: var(--sp4x);

		.hs-container {
			.hs-inner-container {
				.hs-top-info-cont {
					.hs-title {
						width: var(--sp30x);
					}

					.hs-text {
						width: var(--sp50x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--hsPadding: var(--sp1-5x);
		--hsContPaddingTop: var(--sp12x);
		--hsContBorderRadius: var(--sp2x);
		--hsInfoMaxWidth: var(--sp42x);
		--hsTopBtnMarginTop: var(--sp5x);
		--hsAnimRow1PaddingTop: var(--sp26x);
		--hsAnimWidth: 10.41vw;
		--hsAnimRow1MarginRight: 5.98vw;
		--hsAnimRow1MiddleMarginTop: 14.35vw;
		--hsAnimRow2MarginTop: -6vw;
		--hsAnimRow2MarginRight: 13.15vw;
		--hsAnimRow2MiddleMarginTop: 3.51vw;
		--hsAnimMiddleWidth: 13.25vw;
		--hsAnimMiddleMarginTop: 13.28vw;
		--hsSubtitleMaxWidth: var(--sp58x);
		--hsSubtitleMarginTop: var(--sp6x);
		--hsTextMaxWidth: var(--sp59x);
		--hsTextMarginTop: var(--sp5x);
		--hsBottomBtnMarginTop: var(--sp5x);
		--hsTitleMarginBottom: var(--sp4x);

		.hs-container {
			.hs-inner-container {
				.hs-top-info-cont {
					.hs-title {
						width: var(--sp30x);
					}

					.hs-text {
						width: var(--sp58x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--hsPadding: var(--sp1x);
		--hsContPaddingTop: var(--sp12x);
		--hsContBorderRadius: var(--sp2x);
		--hsInfoMaxWidth: 100%;
		--hsTopBtnMarginTop: var(--sp4x);
		--hsAnimRow1PaddingTop: var(--sp36x);
		--hsAnimWidth: 10.14vw;
		--hsAnimRow1MarginRight: 5.79vw;
		--hsAnimRow1MiddleMarginTop: 14vw;
		--hsAnimRow2MarginTop: -6vw;
		--hsAnimRow2MarginRight: 12.8vw;
		--hsAnimRow2MiddleMarginTop: 3.38vw;
		--hsAnimMiddleWidth: 13.25vw;
		--hsAnimMiddleMarginTop: 17.39vw;
		--hsSubtitleMaxWidth: 100%;
		--hsSubtitleMarginTop: var(--sp4x);
		--hsTextMaxWidth: 100%;
		--hsTextMarginTop: var(--sp3x);
		--hsBottomBtnMarginTop: var(--sp4x);
		--hsTitleMarginBottom: var(--sp4x);

		.hs-container {
			.hs-inner-container {
				.hs-top-info-cont {
					.hs-title {
						width: var(--sp30x);
					}

					.hs-text {
						width: var(--sp47x);
					}
				}
			}
		}
	}
`

export default HeroSectionStyle
