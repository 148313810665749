import { memo as Memo } from 'react'

//* Components
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Image from 'components/common/Image'
import Button from 'components/common/Button'

//* Styles
import JoinSectionStyle from './style'

//* Helpers
import { config } from 'helpers'

//* HOC's
import { withUserContext } from 'context'

const JoinSection = Memo(({ user }) => {
    return (
        <Container section>
            <JoinSectionStyle>
                <div className='js-col js-left-cont'>
                    <div className='js-info-cont js-left-info-cont birodo-green-bg'>
                        <Text
                            text={'joinUsText'}
                            className='js-title font-jakarta-sans-semibold h4'
                        />
                        <div className='js-col-image'>
                            <Image src='/images/svg/joinUs.svg' />
                        </div>
                    </div>

                    <Button
                        className='js-offer-button'
                        size={'big'}
                        text={'joinUs'}
                        color='white'
                        backgroundColor='birodoGreen'
                        borderColor='birodoGreen'
                        url={user ? config.routes.account.path : config.routes.signUp.path}
                    />
                </div>

                <div className='js-col js-right-cont birodo-green'>
                    <div className='js-info-cont js-right-info-cont'>
                        <Text
                            text={'applyText'}
                            className='js-title font-jakarta-sans-semibold h4'
                        />
                        <div className='js-col-image'>
                            <Image src='/images/svg/apply.svg' />
                        </div>
                    </div>

                    <Button
                        className='js-offer-button'
                        size={'big'}
                        text={'apply'}
                        color='birodoGreen'
                        backgroundColor='pureWhite'
                        borderColor='birodoGreen'
                        url={config.routes.clubApplication.path}
                    />
                </div>
            </JoinSectionStyle>
        </Container>
    )
})

export default withUserContext(JoinSection, ['user'])
