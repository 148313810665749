import { useMemo, useState, useRef, useEffect } from 'react'

//* HOC's
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

//* Style
import OurAwardsStyle from './style'

//* Lottie
import awardsLottie from 'lottieFiles/awards.json'

//* Helpers
import { useActiveSection } from 'helpers'

//* Components
import Text from 'components/common/Text'
import Container from 'components/common/Container'
import LottieAnimation from 'components/common/LottieAnimation'
import Button from 'components/common/Button'

const OurAwards = ({ title, text, items = [], ...props }) => {
    //! States
    const [isAnimationStart, setIsAnimationStart] = useState(false)

    //! Refs
    const sectionRef = useRef()

    //! Active section
    const activeSection = useActiveSection(sectionRef)

    useEffect(() => {
        if (activeSection) {
            setIsAnimationStart(true)
        }
    }, [activeSection])

    //! Awards
    const storeAwards = useMemo(() => {
        return items.map((award, index) => (
            <div
                key={index}
                className={'col-6 col-t-12'}
            >
                <div className='award-item'>
                    <Text className={'p p2 font-jakarta-sans-medium white'}>{props.translate('awards')} #{++index}</Text>
                    <Text className={'h5 font-jakarta-sans-semibold white award-title'}>{award.title}</Text>
                    <Text className={'p p2 font-jakarta-sans-medium white'}>{award.text}</Text>
                    <Button
                        className={'award-item-button'}
                        backgroundColor={'lemonPie'}
                        color={'birodoGreen'}
                        text={'seeMore'}
                        url={`/awards/${award.slug}`}
                    />
                </div>
            </div>
        ))
    }, [items])

    return items?.length ? (
        <OurAwardsStyle ref={sectionRef}>
            <Container full>
                <LottieAnimation
                    animData={awardsLottie}
                    autoplay={false}
                    isPlay={isAnimationStart}
                    loop={false}
                />
                <Text className={'h2 font-reckless-neue-semibold white our-awards-title'}>{title}</Text>
                <Text className={'p p2 font-jakarta-sans-regular white our-awards-description'}>{text}</Text>
                <div className={'awards-wrap'}>
                    <Container
                        row
                        full
                        className='awards-container'
                    >
                        {storeAwards}
                    </Container>
                </div>
            </Container>
        </OurAwardsStyle>
    ) : null
}

export default withLanguageContext(OurAwards, ['translate'])
