import { memo as Memo, useMemo, useRef, useEffect } from 'react'
import gsap from 'gsap'

//* Components
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Button from 'components/common/Button'
import LottieAnimation from 'components/common/LottieAnimation'

//* Helpers
import { useActiveSection } from 'helpers'

//* Animation JSON's
import door1 from 'lottie/door1.json'
import door2 from 'lottie/door2.json'
import door3 from 'lottie/door3.json'
import door4 from 'lottie/door4.json'
import door5 from 'lottie/door5.json'
import door6 from 'lottie/door6.json'
import door7 from 'lottie/door7.json'
import door8 from 'lottie/door8.json'
import doorDude from 'lottie/doorDude.json'

//* Styles
import HeroSectionStyle from './style'

const HeroSection = Memo(({ title, subtitle, second_title, second_text, btn1Text, btn1Url, btn2Text, btn2Url }) => {
    //! Refs
    const middleAnimRef = useRef()
    const isAnimate = useRef()
    const door1Ref = useRef()
    const door2Ref = useRef()
    const door3Ref = useRef()
    const door4Ref = useRef()
    const door5Ref = useRef()
    const door6Ref = useRef()
    const door7Ref = useRef()
    const door8Ref = useRef()
    const bottomInfoRef = useRef()

    //! States
    const isActiveMiddle = useActiveSection(middleAnimRef, 70)

    //! Doors Animation
    useEffect(() => {
        gsap.to(
            [
                door1Ref.current,
                door4Ref.current,
                door2Ref.current,
                door3Ref.current,
                door5Ref.current,
                door8Ref.current,
                door6Ref.current,
                door7Ref.current,
            ],
            {
                duration: 0.6,
                stagger: 0.2,
                transform: `scale(1)`
            }
        ).then(() => {
            gsap.set(bottomInfoRef.current, { opacity: 1 })
        })
    }, [])

    //! Dudle Animation
    const animateDudle = useMemo(() => {
        if (isActiveMiddle) {
            isAnimate.current = isActiveMiddle
        }
        return isAnimate.current
    }, [isActiveMiddle])

    //! Top Info HTML
    const topInfo = useMemo(() => (
        <div className="hs-top-info-cont">
            <Text tag="h1" text={title} className="hs-title h1 font-reckless-neue-semibold white" />
            <Text tag="h2" text={subtitle} className="hs-text p p2 font-jakarta-sans-regular white" />

            <Button
                className="hs-top-btn"
                url={btn1Url}
                text={btn1Text}
                color={'birodoGreen'}
                backgroundColor={'lemonPie'}
            />
        </div>
    ), [title, subtitle, btn1Text, btn1Url])

    //! Animation Row 1 HTML
    const animRow1 = useMemo(() => (
        <div className="hs-anim-row hs-anim-row-1">
            <div className="hs-anim-col hs-anim-col-1-left">
                <LottieAnimation ref={door1Ref} className="hs-anim hs-anim-1" animData={door1} animateOnHover loop={false} />
                <LottieAnimation ref={door2Ref} className="hs-anim hs-anim-2" animData={door2} animateOnHover loop={false} />
            </div>

            <div className="hs-anim-col hs-anim-col-1-right">
                <LottieAnimation ref={door3Ref} className="hs-anim hs-anim-3" animData={door3} animateOnHover loop={false} />
                <LottieAnimation ref={door4Ref} className="hs-anim hs-anim-4" animData={door4} animateOnHover loop={false} />
            </div>
        </div>
    ), [door1, door2, door3, door4])

    //! Animation Row 2 HTML
    const animRow2 = useMemo(() => (
        <div className="hs-anim-row hs-anim-row-2">
            <div className="hs-anim-col hs-anim-col-2-left">
                <LottieAnimation ref={door5Ref} className="hs-anim hs-anim-5" animData={door5} animateOnHover loop={false} />
                <LottieAnimation ref={door6Ref} className="hs-anim hs-anim-6" animData={door6} animateOnHover loop={false} />
            </div>

            <LottieAnimation ref={middleAnimRef} className="hs-anim-middle" animData={doorDude} loop={false} isPlay={animateDudle} />

            <div className="hs-anim-col hs-anim-col-2-right">
                <LottieAnimation ref={door7Ref} className="hs-anim hs-anim-7" animData={door7} animateOnHover loop={false} />
                <LottieAnimation ref={door8Ref} className="hs-anim hs-anim-8" animData={door8} animateOnHover loop={false} />
            </div>
        </div>
    ), [door5, door6, door7, door8, isActiveMiddle])

    //! Bottom Info HTML
    const bottomInfo = useMemo(() => (
        <div ref={bottomInfoRef} className="hs-bottom-info-cont">
            <Text tag="h2" text={second_title} className="hs-subtitle h3 font-reckless-neue-semibold white" />
            <Text tag="h3" text={second_text} className="hs-text p p2 font-jakarta-sans-regular white" />

            <Button
                className="hs-bottom-btn"
                url={btn2Url}
                text={btn2Text}
                color={'lemonPie'}
                borderColor={'lemonPie'}
                borderSmall={true}
            />
        </div>
    ), [second_title, second_text, btn2Text, btn2Url])

    return (
        <HeroSectionStyle>
            <div className="hs-container">
                <Container className="hs-inner-container">
                    {topInfo}

                    {animRow1}

                    {animRow2}

                    {bottomInfo}
                </Container>
            </div>
        </HeroSectionStyle>
    )
})

export default HeroSection