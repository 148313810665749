import { memo as Memo, useMemo } from 'react'

//* HOC
import withDataContext from 'context/consumerHOC/DataConsumer'

//* Component's
import Page from 'components/common/Page'
import HeroSection from 'components/pages/home/HeroSection'
import OffersSection from 'components/sections/OffersSection'
import ClubsIconSection from 'components/sections/ClubsIconSection'
import TeamSection from 'components/sections/TeamSection'
import TestimonialsSliderSection from 'components/sections/TestimonialsSliderSection'
import FAQSection from 'components/sections/FAQSection'
import JoinSection from 'components/sections/JoinSection'
import OurPartners from 'components/sections/OurPartners'
import OurAwards from 'components/pages/home/OurAwardsSection'

const Home = Memo(({ pages, pageParams, selectedLang, config }) => {
    //! Page Data
    const pageData = useMemo(() => pages?.[pageParams.name]?.[selectedLang], [pages, selectedLang, pageParams.name])

    return (
        <Page
            className={'home'}
            meta={pageData?.content?.meta}
        >
            {pageData && (
                <>
                    <HeroSection
                        {...pageData.content.hero}
                        btn1Text={'joinUs'}
                        btn1Url={config.signUp.path}
                        btn2Text={'aboutUs'}
                        btn2Url={config.aboutUs.path}
                    />

                    <OffersSection
                        title={'whatWeOffer'}
                        offers={pageData.content?.offers}
                    />

                    {pageData.clubs && pageData.clubs.length ? <ClubsIconSection clubs={pageData.clubs} /> : null}

                    <TeamSection
                        section
                        title={'ourTeam'}
                        team={pageData.our_team}
                    />

                    <OurAwards {...pageData.content?.award} />

                    <TeamSection
                        section
                        title={'ourAdvisoryBoard'}
                        team={pageData.our_advisory_board}
                        advisoryBoard={true}
                    />

                    {/* <OurPartners partners={pageData.partners} /> */}

                    {pageData.testimonials.length !== 0 && (
                        <TestimonialsSliderSection
                            section
                            title='trustedByTopTeamleaders'
                            testimonials={pageData.testimonials}
                        />
                    )}

                    {pageData.faqs.length !== 0 && (
                        <FAQSection
                            section
                            data={pageData.faqs}
                        />
                    )}

                    <JoinSection />
                </>
            )}
        </Page>
    )
})

export default withDataContext(Home, ['pages'])
