import styled from 'styled-components'

const TestimonialsSliderSectionStyle = styled.div`
    --tssTitleMarginBottom: var(--sp12x);
    --tssInfoRadius: var(--sp4x);
    --tssSlideWidth: 50%;
    --tssSlideTopPadding: var(--sp10x) var(--sp13x);
    --tssSlideBottomPadding: var(--sp5x) var(--sp13x);
    --tssImageMaxWidth: var(--sp26x);
    --tssImageProportion: 20.19%;
    --tssTextMarginTop: var(--sp8x);

    overflow: hidden;

    .tss-title {
        margin-bottom: var(--tssTitleMarginBottom);
    }

    .tss-slider {
        overflow: visible;
        margin: 0 calc(var(--colPadding) / -2);

        .tss-slide-cont {
            width: var(--tssSlideWidth);
            padding: 0 calc(var(--colPadding) / 2);
            height: auto;

            .tss-slide {
                height: 100%;
                display: flex;
                flex-direction: column;
                text-align: center;

                .tss-slide-top {
                    padding: var(--tssSlideTopPadding);
                    border-radius: var(--tssInfoRadius);
                    flex: 1;

                    .tss-slide-image {
                        width: var(--tssImageMaxWidth);
                        margin: 0 auto;

                        .image-cont {
                            --proportion: var(--tssImageProportion);

                            img {
                                object-fit: contain;
                            }
                        }
                    }

                    .tss-text {
                        margin-top: var(--tssTextMarginTop);
                    }
                }

                .tss-slide-bottom {
                    padding: var(--tssSlideBottomPadding);
                    border-radius: var(--tssInfoRadius);
                }
            }
        }
    }

    .tss-pagination-cont {
        display: none;
    }
    
    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
        --tssTitleMarginBottom: var(--sp9x);
        --tssInfoRadius: var(--sp4x);
        --tssSlideTopPadding: var(--sp7x) var(--sp10x);
        --tssSlideBottomPadding: var(--sp4x) var(--sp10x);
        --tssImageMaxWidth: var(--sp20x);
        --tssTextMarginTop: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
        --tssTitleMarginBottom: var(--sp7x);
        --tssInfoRadius: var(--sp2-5x);
        --tssSlideTopPadding: var(--sp6x) var(--sp8x);
        --tssSlideBottomPadding: var(--sp3x) var(--sp8x);
        --tssImageMaxWidth: var(--sp15x);
        --tssTextMarginTop: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
        --tssTitleMarginBottom: var(--sp7x);
        --tssInfoRadius: var(--sp2-5x);
        --tssSlideTopPadding: var(--sp6x) var(--sp8x);
        --tssSlideBottomPadding: var(--sp3x) var(--sp8x);
        --tssImageMaxWidth: var(--sp15x);
        --tssTextMarginTop: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
        --tssTitleMarginBottom: var(--sp6x);
        --tssInfoRadius: var(--sp2-5x);
        --tssSlideTopPadding: var(--sp5x) var(--sp7x);
        --tssSlideBottomPadding: var(--sp3x) var(--sp7x);
        --tssImageMaxWidth: var(--sp15x);
        --tssTextMarginTop: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
        --tssTitleMarginBottom: var(--sp5x);
        --tssInfoRadius: var(--sp2x);
        --tssSlideTopPadding: var(--sp4x) var(--sp6x);
        --tssSlideBottomPadding: var(--sp3x) var(--sp5x);
        --tssImageMaxWidth: var(--sp12x);
        --tssTextMarginTop: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
        --tssTitleMarginBottom: var(--sp5x);
        --tssInfoRadius: var(--sp2x);
        --tssSlideWidth: 74.166%;
        --tssSlideTopPadding: var(--sp4x) var(--sp5x);
        --tssSlideBottomPadding: var(--sp3x) var(--sp5x);
        --tssImageMaxWidth: var(--sp12x);
        --tssTextMarginTop: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
        --tssTitleMarginBottom: var(--sp5x);
        --tssInfoRadius: var(--sp1-5x);
        --tssSlideWidth: 100%;
        --tssSlideTopPadding: var(--sp4x) var(--sp5x);
        --tssSlideBottomPadding: var(--sp3x) var(--sp5x);
        --tssImageMaxWidth: var(--sp12x);
        --tssTextMarginTop: var(--sp3x);

        .tss-pagination-cont {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: var(--sp3x);

            .tss-pagination-item {
                width: var(--sp1-5x);
                height: var(--sp1-5x);
                border-radius: 2px;
                background-color: var(--cryoFreeze);

                &.active {
                    background-color: var(--birodoGreen);
                }
            }
        }
	}
`

export default TestimonialsSliderSectionStyle