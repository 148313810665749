import styled from 'styled-components'

const OurAwardsStyle = styled.div`
	--ourAwardsPadding: var(--sp25x) var(--sp16x);
	--ourAwardsMarginLeftRight: var(--sp4x);
	--ourAwardsBorderRadius: var(--sp4x);
	--lottieContainerWidth: var(--sp90x);
	--ourAwardsTitleMarginTopBottom: var(--sp13x) 0 var(--sp9x) 0;
	--ourAwardsDescriptionMaxWidth: var(--sp137x);
	--awardsContainerMarginTop: var(--sp13x);
	--awardItemPadding: var(--sp9x) var(--sp8x);
	--awardItemMarginTop: var(--sp5x);
	--awardTitleMarginTopBottom: var(--sp2x) 0 var(--sp5x) 0;
	--awardItemButtonMarginTop: var(--sp8x);
	--awardItemBorderWidth: var(--sp0-5x);
	--ourAwardsMarginBottom: var(--sp30x);

	padding: var(--ourAwardsPadding);
	background-color: var(--birodoGreen);
	border-radius: var(--ourAwardsBorderRadius);
	margin: 0 var(--ourAwardsMarginLeftRight);

	margin-bottom: var(--ourAwardsMarginBottom);

	.lottie-container {
		display: flex;
		justify-content: center;
		margin: 0 auto;
		width: var(--lottieContainerWidth);
	}

	.our-awards-title {
		text-align: center;
		margin: var(--ourAwardsTitleMarginTopBottom);
	}

	.our-awards-description {
		margin: 0 auto;
		text-align: center;
		max-width: var(--ourAwardsDescriptionMaxWidth);
	}

	.awards-wrap {
		margin-top: var(--awardsContainerMarginTop);

		.awards-container {
			margin-top: calc(var(--awardItemMarginTop) * -1);

			.row {
				justify-content: center;
			}

			.award-item {
				padding: var(--awardItemPadding);
				border-radius: var(--ourAwardsBorderRadius);
				border: var(--awardItemBorderWidth) solid var(--white);
				margin-top: var(--awardItemMarginTop);

				.award-title {
					margin: var(--awardTitleMarginTopBottom);
				}

				.award-item-button {
					margin-top: var(--awardItemButtonMarginTop);
					width: fit-content;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--ourAwardsPadding: var(--sp25x) var(--sp11x);
		--ourAwardsMarginLeftRight: var(--sp2-5x);
		--ourAwardsBorderRadius: var(--sp4x);
		--lottieContainerWidth: var(--sp68x);
		--ourAwardsTitleMarginTopBottom: var(--sp9x) 0 var(--sp7x) 0;
		--ourAwardsDescriptionMaxWidth: var(--sp104x);
		--awardsContainerMarginTop: var(--sp9x);
		--awardItemPadding: var(--sp7x) var(--sp6x);
		--awardItemMarginTop: var(--sp4x);
		--awardTitleMarginTopBottom: var(--sp1x) 0 var(--sp6x) 0;
		--awardItemButtonMarginTop: var(--sp6x);
		--ourAwardsMarginBottom: var(--sp25x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--ourAwardsPadding: var(--sp20x) var(--sp10x);
		--ourAwardsMarginLeftRight: var(--sp2-5x);
		--ourAwardsBorderRadius: var(--sp2-5x);
		--lottieContainerWidth: var(--sp52x);
		--ourAwardsTitleMarginTopBottom: var(--sp8x) 0 var(--sp6x) 0;
		--ourAwardsDescriptionMaxWidth: var(--sp80x);
		--awardsContainerMarginTop: var(--sp8x);
		--awardItemPadding: var(--sp6x) var(--sp5x);
		--awardItemMarginTop: var(--sp3x);
		--awardTitleMarginTopBottom: var(--sp1x) 0 var(--sp5x) 0;
		--awardItemButtonMarginTop: var(--sp5x);
		--ourAwardsMarginBottom: var(--sp20x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--ourAwardsPadding: var(--sp22x) var(--sp9x);
		--ourAwardsMarginLeftRight: var(--sp2-5x);
		--ourAwardsBorderRadius: var(--sp2-5x);
		--lottieContainerWidth: var(--sp50x);
		--ourAwardsTitleMarginTopBottom: var(--sp8x) 0 var(--sp6x) 0;
		--ourAwardsDescriptionMaxWidth: var(--sp76x);
		--awardsContainerMarginTop: var(--sp8x);
		--awardItemPadding: var(--sp6x) var(--sp5x);
		--awardItemMarginTop: var(--sp3x);
		--awardTitleMarginTopBottom: var(--sp1x) 0 var(--sp5x) 0;
		--awardItemButtonMarginTop: var(--sp5x);
		--ourAwardsMarginBottom: var(--sp20x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--ourAwardsPadding: var(--sp17x) var(--sp6x);
		--ourAwardsMarginLeftRight: var(--sp2x);
		--ourAwardsBorderRadius: var(--sp2-5x);
		--lottieContainerWidth: var(--sp46x);
		--ourAwardsTitleMarginTopBottom: var(--sp7x) 0 var(--sp5x) 0;
		--ourAwardsDescriptionMaxWidth: var(--sp70x);
		--awardsContainerMarginTop: var(--sp7x);
		--awardItemPadding: var(--sp5x) var(--sp4x);
		--awardItemMarginTop: var(--sp3x);
		--awardTitleMarginTopBottom: var(--sp1-5x) 0 var(--sp4x) 0;
		--awardItemButtonMarginTop: var(--sp4x);
		--ourAwardsMarginBottom: var(--sp17x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--ourAwardsPadding: var(--sp12x) var(--sp2-5x);
		--ourAwardsMarginLeftRight: var(--sp1-5x);
		--ourAwardsBorderRadius: var(--sp2x);
		--lottieContainerWidth: var(--sp38x);
		--ourAwardsTitleMarginTopBottom: var(--sp7x) 0 var(--sp4x) 0;
		--ourAwardsDescriptionMaxWidth: var(--sp58x);
		--awardsContainerMarginTop: var(--sp7x);
		--awardItemPadding: var(--sp5x) var(--sp4x);
		--awardItemMarginTop: var(--sp3x);
		--awardTitleMarginTopBottom: var(--sp1x) 0 var(--sp3x) 0;
		--awardItemButtonMarginTop: var(--sp4x);
		--awardItemBorderWidth: 3px;
		--ourAwardsMarginBottom: var(--sp12x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--ourAwardsPadding: var(--sp12x) var(--sp1-5x);
		--ourAwardsMarginLeftRight: var(--sp1-5x);
		--ourAwardsBorderRadius: var(--sp2x);
		--lottieContainerWidth: var(--sp28x);
		--ourAwardsTitleMarginTopBottom: var(--sp7x) 0 var(--sp4x) 0;
		--ourAwardsDescriptionMaxWidth: var(--sp59x);
		--awardsContainerMarginTop: var(--sp8x);
		--awardItemPadding: var(--sp5x) var(--sp4x);
		--awardItemMarginTop: var(--sp3x);
		--awardTitleMarginTopBottom: var(--sp1x) 0 var(--sp3x) 0;
		--awardItemButtonMarginTop: var(--sp5x);
		--awardItemBorderWidth: 3px;
		--ourAwardsMarginBottom: var(--sp12x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--ourAwardsPadding: var(--sp9x) var(--sp1x);
		--ourAwardsMarginLeftRight: var(--sp1x);
		--ourAwardsBorderRadius: var(--sp1x);
		--lottieContainerWidth: var(--sp23x);
		--ourAwardsTitleMarginTopBottom: var(--sp5x) 0 var(--sp3x) 0;
		--ourAwardsDescriptionMaxWidth: 100%;
		--awardsContainerMarginTop: var(--sp5x);
		--awardItemPadding: var(--sp4x) var(--sp3x);
		--awardItemMarginTop: var(--sp2x);
		--awardTitleMarginTopBottom: var(--sp0-5x) 0 var(--sp3x) 0;
		--awardItemButtonMarginTop: var(--sp4x);
		--awardItemBorderWidth: 3px;
		--ourAwardsMarginBottom: var(--sp9x);
	}
`

export default OurAwardsStyle
