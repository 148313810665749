import { memo as Memo, useMemo, useRef, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

//* Components
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Image from 'components/common/Image'

//* Styles
import TestimonialsSliderSectionStyle from './style'

const TestimonialsSliderSection = Memo(({ title, testimonials, section, first }) => {
    //! States
    const [activeIndex, setActiveIndex] = useState(0)

    //! Refs
    const sliderRef = useRef()

    //! Slide to active category
    useEffect(() => {
        if (typeof activeIndex === 'number' && sliderRef.current) {
            sliderRef.current.slideTo(activeIndex)
        }
    }, [activeIndex])

    //! Slides
    const slides = useMemo(() => {
        return testimonials.map((t, k) => {
            return (
                <SwiperSlide key={k} className="tss-slide-cont birodo-green">
                    <div className="tss-slide">
                        <div className="tss-slide-top mandys-pink-bg">
                            <div className="tss-slide-image">
                                <Image src={t.logo.src} alt={t.logo.alt} />
                            </div>

                            <Text text={`“${t.text}”`} className="tss-text p p1 font-jakarta-sans-semibold" />
                        </div>
                        <div className="tss-slide-bottom mandys-pink-bg">
                            <Text text={`${t.position}, ${t.full_name}`} className="tss-info p p2 font-jakarta-sans-medium " />
                        </div>
                    </div>
                </SwiperSlide>
            )
        })
    }, [testimonials])

    //! Pagination
    const pagination = useMemo(() => {
        return (
            <div className="tss-pagination-cont">
                {testimonials.map((t, k) => <span key={k} onClick={() => setActiveIndex(k)} className={`tss-pagination-item ${activeIndex === k ? 'active' : ''}`} />)}
            </div>
        )
    }, [testimonials, activeIndex])

    return (
        <Container full section={section} first={first} >
            <TestimonialsSliderSectionStyle>
                <Container >
                    <Text tag="h2" text={title} className="tss-title h3 font-reckless-neue-semibold birodo-green" />

                    <Swiper
                        onSwiper={ref => sliderRef.current = ref}
                        onSlideChange={r => setActiveIndex(r.activeIndex)}
                        className="tss-slider"
                        slidesPerView={'auto'}
                        loop={false}
                    >
                        {slides}
                    </Swiper>

                    {pagination}
                </Container>
            </TestimonialsSliderSectionStyle>
        </Container>
    )
})

export default TestimonialsSliderSection